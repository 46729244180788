import { useTranslation } from "react-i18next";
import type { ModalData } from "@/src/ui/components/sure_modal/sure_modal";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useShowToast from "@/src/ui/hooks/useShowToast";
import { useMutationChannelProvider } from "../../provider/mutation_channel.provider";
import paths from "@/src/ui/router/paths";
import { useEffectRunOnce } from "@front_web_mrmilu/hooks";
import type { Id } from "@/src/common/utils/types";
import { Delete, DeviceHub } from "@/src/ui/assets/icons";
import Styled from "./detail_channel_page.styled";
import DetailPageHeader from "@/src/ui/components/detail_page_header/detail_page_header";
import DetailChannel from "../../components/detail_channel/detail_channel";
import ChannelController from "../../controllers/channel_controller";

export default function DetailChannelPage() {
  const { t } = useTranslation(["channel", "common"]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData>();
  const navigate = useNavigate();
  const { showToast } = useShowToast();
  const getChannelById = useMutationChannelProvider((state) => state.getById);
  const channel = useMutationChannelProvider((state) => state.item);
  const { channelId } = useParams();

  const onCloseModal = useCallback(() => setShowModal(false), []);

  const getChannel = useCallback(async () => {
    if (channelId) {
      const id = Number(channelId);
      const response = await getChannelById(id);
      if (!response) {
        navigate(paths.channel.index);
      }
    }
  }, [getChannelById, channelId, navigate]);

  useEffectRunOnce(() => {
    getChannel();
  }, [getChannel]);

  const onClickDelete = useCallback(
    (id: Id) => {
      setShowModal(true);

      const newModalData: ModalData = {
        description: t("channel:sure.delete"),
        primaryModalButtonText: t("common:actions.remove"),
        onClickPrimaryButton: async () => {
          await ChannelController.delete(id);
          onCloseModal();
          navigate(paths.channel.index);
          showToast({ message: t("channel:actions.deleted") });
        }
      };
      setModalData(newModalData);
    },
    [navigate, onCloseModal, t, showToast]
  );

  const actions = useMemo(
    () =>
      channel
        ? [
            {
              Icon: Delete,
              text: t("channel:actions.delete", { ns: "contact" }),
              onClick: () => onClickDelete(Number(channelId))
            }
          ]
        : [],
    [channel, onClickDelete, channelId, t]
  );

  return (
    <Styled.Page>
      <DetailPageHeader
        Icon={DeviceHub}
        resourcePath={paths.channel.index}
        resourceName={t("channel:channels")}
        headerText={channel ? channel.name : ""}
        creationInformation={undefined}
        actions={actions}
        showModal={showModal}
        onCloseModal={onCloseModal}
        modalPrimaryButtonText={modalData?.primaryModalButtonText}
        modalDescription={modalData?.description}
        onPrimaryButtonClick={modalData?.onClickPrimaryButton}
        showBreadcrumbs={true}
      />
      <DetailChannel channel={channel} />
    </Styled.Page>
  );
}
