import { useRoutes } from "react-router-dom";
import { useContractStatsProvider } from "@/src/ui/pages/home/provider/contract_stats.provider";
import { lazy } from "react";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { useListContractProvider } from "@/src/ui/pages/contract/views/contract_page/provider/list_contract.provider";
import { useAutocompleteRateTypesProvider } from "@/src/ui/provider/autocomplete_rate_types.provider";
import { useAutocompleteMarketersProvider } from "@/src/ui/pages/rate/provider/autocomplete_marketer.provider";
import { useAutocompleteRateProvider } from "@/src/ui/provider/autocomplete_rate.provider";
import { useListRateProvider } from "@/src/ui/pages/rate/provider/list_rate.provider";
import { useListProfileProvider } from "@/src/ui/pages/profile/views/profile_page/provider/list_profile.provider";

const HomePage = lazy(() => import("@/src/ui/pages/home/views/home_page/home_page"));

export default function HomePages() {
  const page = useRoutes([
    {
      index: true,
      element: <HomePage />
    }
  ]);

  return (
    <AppErrorBoundary key="home-error">
      <useContractStatsProvider.State>
        <useAutocompleteRateTypesProvider.State>
          <useAutocompleteMarketersProvider.State>
            <useAutocompleteRateProvider.State>
              <useListContractProvider.State>
                <useListRateProvider.State>
                  <useListProfileProvider.State>{page}</useListProfileProvider.State>
                </useListRateProvider.State>
              </useListContractProvider.State>
            </useAutocompleteRateProvider.State>
          </useAutocompleteMarketersProvider.State>
        </useAutocompleteRateTypesProvider.State>
      </useContractStatsProvider.State>
    </AppErrorBoundary>
  );
}
