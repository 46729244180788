import type { AtLeast, Id } from "@/src/common/utils/types";
import { createProvider } from "@/src/common/utils/zustand";
import type { RoleModel } from "@/src/core/role/domain/models/role_model";
import type { CreateRoleModel } from "@/src/core/role/domain/models/create_role_model";
import type { PatchRoleModel } from "@/src/core/role/domain/models/patch_role_model";
import { getMutationInitialState } from "@/src/ui/provider/mutation.slice";
import type { MutationState } from "@/src/ui/view_models/mutation_state";
import RoleController from "../controllers/role_controller";

export const useMutationRoleProvider = createProvider<MutationState<RoleModel, CreateRoleModel>>(() => (set) => ({
  async edit(input: CreateRoleModel, id: Id) {
    const data: AtLeast<PatchRoleModel, "id"> = { ...input, id };
    return await RoleController.patch(data);
  },
  ...getMutationInitialState(set, new RoleController())
}));
