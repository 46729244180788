import { useNavigate, useRoutes } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import { useListRoleProvider } from "@/src/ui/pages/role/views/role_page/provider/list_role.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { useMutationRoleProvider } from "@/src/ui/pages/role/provider/mutation_role.provider";
import paths from "@/src/ui/router/paths";
import DetailRolePage from "../views/detail_role_page/detail_role_page";
import EditRolePage from "../views/edit_role_page/edit_role_page";

const ListRolePage = lazy(() => import("@/src/ui/pages/role/views/role_page/role_page"));
const CreateRolePage = lazy(() => import("@/src/ui/pages/role/views/create_role_page/create_role_page"));

const ID_PATH_PARAM = ":roleId";

export default function RolePages() {
  const navigate = useNavigate();
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    setRouteList([
      {
        index: true,
        element: (
          <useListRoleProvider.State>
            <ListRolePage />
          </useListRoleProvider.State>
        )
      },
      {
        path: paths.role.create,
        element: <CreateRolePage />
      },
      {
        path: paths.role.detail + ID_PATH_PARAM,
        element: <DetailRolePage />
      },
      {
        path: paths.role.edit + ID_PATH_PARAM,
        element: <EditRolePage />
      }
    ]);
  }, [navigate]);

  const page = useRoutes(routeList);
  return (
    <AppErrorBoundary key="role-error">
      <useMutationRoleProvider.State>{page}</useMutationRoleProvider.State>
    </AppErrorBoundary>
  );
}
