import type { Id } from "@/src/common/utils/types";
import { createProvider } from "@/src/common/utils/zustand";
import type { CreateDocumentModel } from "@/src/core/document/domain/models/create_document_model";
import DocumentController from "@/src/ui/pages/document/controllers/document_controller";
import { getMutationInitialState } from "@/src/ui/provider/mutation.slice";
import type { MutationState } from "@/src/ui/view_models/mutation_state";
import type { DetailDocumentModel } from "@/src/core/document/domain/models/detail_document_model";

export const useMutationDocumentProvider = createProvider<MutationState<DetailDocumentModel, CreateDocumentModel>>(() => (set) => ({
  async edit(input: CreateDocumentModel, id: Id) {
    const response = await DocumentController.edit(input, id);
    set({ item: response });
    return response;
  },
  ...getMutationInitialState(set, new DocumentController())
}));
