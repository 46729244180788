import { createProvider } from "@/src/common/utils/zustand";
import type { ChannelModel } from "@/src/core/channel/domain/models/channel_model";
import type { ListState } from "@/src/ui/view_models/list_state";
import type { ChannelFilterValues } from "../view_model/channel_filters.values";
import type { SortChannel } from "@/src/core/channel/domain/interfaces/sort_channel";
import type { OrderBy } from "@/src/core/app/domain/models/order";
import { getListInitialState } from "@/src/ui/provider/list.slice";
import ChannelController from "../../../controllers/channel_controller";

export const useListChannelProvider = createProvider<ListState<ChannelModel, ChannelFilterValues, SortChannel>>(() => (set, get) => ({
  filters: {
    name: "",
    socialName: "",
    cif: ""
  },
  setOrderBy(newOrderBy: OrderBy<ChannelModel>) {
    set({ orderBy: newOrderBy });
    get().getAll(get().filters);
  },
  ...getListInitialState(get, set, new ChannelController())
}));
