import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import paths, { WILDCARD_PATH } from "@/src/ui/router/paths";
import { useListMarketerProvider } from "@/src/ui/pages/marketer/views/marketer_page/provider/list_marketer.provider";
import { useMutationMarketerProvider } from "@/src/ui/pages/marketer/providers/mutation_marketer.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { useSelector } from "react-redux";
import type { RootState, UserPermissions } from "@/src/redux/store";

const ListMarketerPage = lazy(() => import("@/src/ui/pages/marketer/views/marketer_page/marketer_page"));
const CreateMarketerPage = lazy(() => import("@/src/ui/pages/marketer/views/create_marketer_page/create_marketer_page"));
const EditMarketerPage = lazy(() => import("@/src/ui/pages/marketer/views/edit_marketer_page/edit_marketer_page"));
const DetailMarketerPage = lazy(() => import("@/src/ui/pages/marketer/views/detail_marketer_page/detail_marketer_page"));
const ID_PATH_PARAM = ":marketerId";

export default function MarketerPages() {
  const navigate = useNavigate();
  const userPermissions = useSelector((state: RootState) => state.app.userPermissions as unknown as UserPermissions);
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    if (userPermissions) {
      if (userPermissions && userPermissions["marketers"] && userPermissions["marketers"].list) {
        let list = [];
        list.push(
          {
            index: true,
            element: (
              <useListMarketerProvider.State>
                <ListMarketerPage />
              </useListMarketerProvider.State>
            )
          },
          {
            path: paths.marketer.detail + ID_PATH_PARAM,
            element: <DetailMarketerPage />
          },
          { path: WILDCARD_PATH, element: <Navigate to={paths.marketer.index} replace /> }
        );

        if (userPermissions["marketers"].create) {
          list = list.concat([
            {
              path: paths.marketer.create,
              element: <CreateMarketerPage />
            },
            {
              path: paths.marketer.edit + ID_PATH_PARAM,
              element: <EditMarketerPage />
            }
          ]);
        }
        setRouteList(list);
      } else {
        navigate("/");
      }
    }
  }, [userPermissions, navigate]);

  const page = useRoutes(routeList);

  return (
    <AppErrorBoundary key="marketer-error">
      <useMutationMarketerProvider.State>{page}</useMutationMarketerProvider.State>
    </AppErrorBoundary>
  );
}
