import { configureStore } from "@reduxjs/toolkit";
import appSlice from "./slices/appSlice";

interface Permission {
  list: boolean;
  create: boolean;
  status_change: boolean | null;
}
export interface UserPermissions {
  channels: Permission;
  users: Permission;
  rate_types: Permission;
  energy_costs: Permission;
  marketers: Permission;
  rates: Permission;
  costs: Permission;
  commissions: Permission;
  margins: Permission;
  saving_studies: Permission;
  clients: Permission;
  supply_points: Permission;
  contracts: Permission;
  invoices: Permission;
  settlements: Permission;
  roles: Permission;
  operations: boolean;
}

const store = configureStore({
  reducer: {
    app: appSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
