import Styled from "./single_permission_checkbox.styled";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { SwitchBaseProps } from "@mui/material/internal/SwitchBase";

interface InnerProps {
  list?: boolean;
  create?: boolean;
  delete?: boolean;
  status_change?: boolean;
}

interface Props {
  disabled?: boolean;
  name: string;
  onChange?: SwitchBaseProps["onChange"];
  options: InnerProps;
}

export function SinglePermissionCheckbox({ name, options, onChange, disabled = false }: Props) {
  const { t } = useTranslation("role", { useSuspense: true });

  const labels = t("labels", { returnObjects: true });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const key: string = labels[name];
  const list: string = labels["list"];
  const create: string = labels["create"];
  const remove: string = labels["delete"];
  const status_change: string = labels["status_change"];

  return (
    <Styled.SinglePermissionCheckbox>
      <h2>{key}</h2>
      <div>
        <FormControlLabel
          control={<Checkbox id={name} defaultChecked={options.list} name="list" onChange={onChange} disabled={disabled} />}
          label={list}
        />
        <FormControlLabel
          control={<Checkbox id={name} defaultChecked={options.create} name="create" onChange={onChange} disabled={disabled} />}
          label={create}
        />
        <FormControlLabel
          control={<Checkbox id={name} defaultChecked={options.delete} name="delete" onChange={onChange} disabled={disabled} />}
          label={remove}
        />
        {options.status_change !== null && (
          <FormControlLabel
            control={<Checkbox id={name} defaultChecked={options.status_change} name="status_change" disabled={disabled} onChange={onChange} />}
            label={status_change}
          />
        )}
      </div>
    </Styled.SinglePermissionCheckbox>
  );
}
