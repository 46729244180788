import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { ListController } from "@/src/common/interfaces/list_controller";
import type { SortDocument } from "@/src/core/document/domain/interfaces/sort_document";
import type { DocumentModel } from "@/src/core/document/domain/models/document_model";
import type { FilterDocumentModel } from "@/src/core/document/domain/models/filter_document_model";
import type { MutationController } from "@/src/common/interfaces/mutation_controller";
import type { Filters } from "@/src/core/app/domain/models/filters";
import { withErrorHandler } from "@/src/common/utils/errors";
import type { GetDocumentsSupplyPointUseCase } from "@/src/core/supply_point/domain/use_cases/get_documents_supply_point_use_case";
import { ExportArgumentsModel } from "@/src/core/app/domain/models/export_argument";
import type { Id } from "@/src/common/utils/types";
import type { DeleteManyDocumentUseCase } from "@/src/core/document/domain/use_cases/delete_many_documents_use_case";
import type { ExportDocumentsUseCase } from "@/src/core/document/domain/use_cases/export_documents_use_case";
import type { GetDocumentDetailSupplyPointUseCase } from "@/src/core/document/domain/use_cases/get_document_supply_point_detail_use_case";
import type { DeleteManyDocumentSupplyPointUseCase } from "@/src/core/document/domain/use_cases/delete_many_documents_supply_point_use_case";
import type { CreateDocumentSupplyPointModel } from "@/src/core/document/domain/models/create_document_supply_point_model";
import type { EditDocumentSupplyPointUseCase } from "@/src/core/document/domain/use_cases/edit_document_supply_point_use_case";
import type { CreateDocumentSupplyPointUseCase } from "@/src/core/document/domain/use_cases/create_document_supply_point_use_case";

export default class DocumentSupplyPointController
  implements ListController<DocumentModel, FilterDocumentModel, SortDocument>, MutationController<DocumentModel>
{
  async getAll(filter: Filters<FilterDocumentModel, SortDocument>) {
    const documentsUseCase = await locator.get<IocProvider<GetDocumentsSupplyPointUseCase>>(TYPES.GetDocumentsSupplyPointUseCase)();
    return await withErrorHandler(documentsUseCase.execute(filter));
  }

  static async create(input: CreateDocumentSupplyPointModel) {
    const createSupplyPointUseCase = await locator.get<IocProvider<CreateDocumentSupplyPointUseCase>>(TYPES.CreateDocumentSupplyPointUseCase)();
    return await withErrorHandler(createSupplyPointUseCase.execute(input));
  }

  async getOneById(id: Id) {
    const getDocumentUseCase = await locator.get<IocProvider<GetDocumentDetailSupplyPointUseCase>>(TYPES.GetDocumentDetailSupplyPointUseCase)();
    return await withErrorHandler(getDocumentUseCase.execute(id));
  }

  static async edit(input: CreateDocumentSupplyPointModel, id: Id) {
    const editDocumentUseCase = await locator.get<IocProvider<EditDocumentSupplyPointUseCase>>(TYPES.EditDocumentSupplyPointUseCase)();
    return withErrorHandler(editDocumentUseCase.execute(input, id));
  }

  async deleteMany(ids: Id[]) {
    const deleteManyUseCase = await locator.get<IocProvider<DeleteManyDocumentUseCase>>(TYPES.DeleteManyDocumentUseCase)();
    await withErrorHandler(deleteManyUseCase.execute(ids));
  }

  async deleteManySupplyPointDocuments(ids: Id[]) {
    const deleteManyUseCase = await locator.get<IocProvider<DeleteManyDocumentSupplyPointUseCase>>(TYPES.DeleteManyDocumentSupplyPointUseCase)();
    await withErrorHandler(deleteManyUseCase.supplyPointDocuments(ids));
  }

  async export({ filters, ids }: ExportArgumentsModel<FilterDocumentModel, SortDocument>) {
    const exportUseCase = await locator.get<IocProvider<ExportDocumentsUseCase>>(TYPES.ExportDocumentsUseCase)();
    const exportArguments = new ExportArgumentsModel({ filters, ids });
    return await withErrorHandler(exportUseCase.execute(exportArguments));
  }
}
