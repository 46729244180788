export class ValidateEmailModel {
  password: string;
  userId?: string;
  token?: string;

  constructor(validateEmail: { password: string; userId?: string; token: string }) {
    this.password = validateEmail.password;

    if (validateEmail.userId) {
      this.userId = validateEmail.userId;
    }

    if (validateEmail.token) {
      this.token = validateEmail.token;
    }
  }
}
