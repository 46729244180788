import type { AtLeast, Id } from "@/src/common/utils/types";
import { createProvider } from "@/src/common/utils/zustand";
import type { ChannelModel } from "@/src/core/channel/domain/models/channel_model";
import type { CreateChannelModel } from "@/src/core/channel/domain/models/create_channel_model";
import type { PatchChannelModel } from "@/src/core/channel/domain/models/patch_channel_model";
import { getMutationInitialState } from "@/src/ui/provider/mutation.slice";
import type { MutationState } from "@/src/ui/view_models/mutation_state";
import ChannelController from "../controllers/channel_controller";

export const useMutationChannelProvider = createProvider<MutationState<ChannelModel, CreateChannelModel>>(() => (set) => ({
  async edit(input: CreateChannelModel, id: Id) {
    const data: AtLeast<PatchChannelModel, "id"> = { ...input, id };
    return await ChannelController.patch(data);
  },
  ...getMutationInitialState(set, new ChannelController())
}));
