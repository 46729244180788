import { createProvider } from "@/src/common/utils/zustand";
import type { RoleModel } from "@/src/core/role/domain/models/role_model";
import type { ListState } from "@/src/ui/view_models/list_state";
import type { RoleFilterValues } from "../view_model/role_filters.values";
import type { SortRole } from "@/src/core/role/domain/interfaces/sort_role";
import type { OrderBy } from "@/src/core/app/domain/models/order";
import { getListInitialState } from "@/src/ui/provider/list.slice";
import RoleController from "../../../controllers/role_controller";

export const useListRoleProvider = createProvider<ListState<RoleModel, RoleFilterValues, SortRole>>(() => (set, get) => ({
  filters: {
    name: "",
    usersCount: undefined,
    createdAt: "",
    channel: undefined
  },
  setOrderBy(newOrderBy: OrderBy<RoleModel>) {
    set({ orderBy: newOrderBy });
    get().getAll(get().filters);
  },
  ...getListInitialState(get, set, new RoleController())
}));
