import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import paths, { WILDCARD_PATH } from "@/src/ui/router/paths";
import { useListEnergyCostProvider } from "@/src/ui/pages/energy_cost/views/energy_cost_page/provider/list_energy_cost.provider";
import { useMutationEnergyCostProvider } from "@/src/ui/pages/energy_cost/provider/mutatiton_energy_cost.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { useSelector } from "react-redux";
import type { RootState, UserPermissions } from "@/src/redux/store";

const ListEnergyCostPage = lazy(() => import("@/src/ui/pages/energy_cost/views/energy_cost_page/energy_cost_page"));
const CreateEnergyCostPage = lazy(() => import("@/src/ui/pages/energy_cost/views/create_energy_cost_page/create_energy_cost_page"));
const DetailEnergyCostPage = lazy(() => import("@/src/ui/pages/energy_cost/views/detail_energy_cost_page/detail_energy_cost_page"));
const EditEnergyCostPage = lazy(() => import("@/src/ui/pages/energy_cost/views/edit_energy_cost_page/edit_energy_cost_page"));

const ID_PATH_PARAM = ":energyCostId";

export default function EnergyCostPages() {
  const navigate = useNavigate();
  const userPermissions = useSelector((state: RootState) => state.app.userPermissions as unknown as UserPermissions);
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    if (userPermissions) {
      if (userPermissions && userPermissions["energy_costs"] && userPermissions["energy_costs"].list) {
        let list = [];
        list.push(
          {
            index: true,
            element: (
              <useListEnergyCostProvider.State>
                <ListEnergyCostPage />
              </useListEnergyCostProvider.State>
            )
          },
          {
            path: paths.energyCost.detail + ID_PATH_PARAM,
            element: <DetailEnergyCostPage />
          },
          { path: WILDCARD_PATH, element: <Navigate to={paths.energyCost.index} replace /> }
        );

        if (userPermissions["energy_costs"].create) {
          list = list.concat([
            {
              path: paths.energyCost.create,
              element: <CreateEnergyCostPage />
            },
            {
              path: paths.energyCost.edit + ID_PATH_PARAM,
              element: <EditEnergyCostPage />
            }
          ]);
        }
        setRouteList(list);
      } else {
        navigate("/");
      }
    }
  }, [userPermissions, navigate]);

  const page = useRoutes(routeList);

  return (
    <AppErrorBoundary key="energy-cost-error">
      <useMutationEnergyCostProvider.State>{page}</useMutationEnergyCostProvider.State>
    </AppErrorBoundary>
  );
}
