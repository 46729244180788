import type { ListController } from "@/src/common/interfaces/list_controller";
import type { MutationController } from "@/src/common/interfaces/mutation_controller";
import type { SortChannel } from "@/src/core/channel/domain/interfaces/sort_channel";
import type { ChannelModel } from "@/src/core/channel/domain/models/channel_model";
import type { FilterChannelModel } from "@/src/core/channel/domain/models/filter_channel_model";
import { Filters } from "@/src/core/app/domain/models/filters";
import type { Id, AtLeast } from "@/src/common/utils/types";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { ChannelUseCase } from "@/src/core/channel/domain/use_cases/channels_use_case";
import { withErrorHandler } from "@/src/common/utils/errors";
import type { GetChannelDetailUseCase } from "@/src/core/channel/domain/use_cases/get_channel_detail_use_case";
import type { CreateChannelModel } from "@/src/core/channel/domain/models/create_channel_model";
import type { CreateChannelUseCase } from "@/src/core/channel/domain/use_cases/create_channel_use_case";
import type { PatchChannelUseCase } from "@/src/core/channel/domain/use_cases/patch_channel_use_case";
import type { PatchChannelModel } from "@/src/core/channel/domain/models/patch_channel_model";
import { ExportArgumentsModel } from "@/src/core/app/domain/models/export_argument";
import type { DeleteManyChannelUseCase } from "@/src/core/channel/domain/use_cases/delete_many_channel_use_case";
import type { ExportChannelUseCase } from "@/src/core/channel/domain/use_cases/export_channel_use_case";

export default class ChannelController implements ListController<ChannelModel, FilterChannelModel, SortChannel>, MutationController<ChannelModel> {
  async getAll(filter: Filters<FilterChannelModel, SortChannel>) {
    const getUseCase = await locator.get<IocProvider<ChannelUseCase>>(TYPES.ChannelUseCase)();
    return await withErrorHandler(getUseCase.execute(filter));
  }

  async getOneById(id: Id) {
    const getDetailUseCase = await locator.get<IocProvider<GetChannelDetailUseCase>>(TYPES.GetChannelDetailUseCase)();
    return await withErrorHandler(getDetailUseCase.execute(id));
  }

  static async create(input: CreateChannelModel) {
    const createChannelUseCase = await locator.get<IocProvider<CreateChannelUseCase>>(TYPES.CreateChannelUseCase)();
    await withErrorHandler(createChannelUseCase.execute(input));
  }

  static async delete(id: Id) {
    const patchUseCase = await locator.get<IocProvider<PatchChannelUseCase>>(TYPES.PatchChannelUseCase)();
    await withErrorHandler(patchUseCase.delete(id));
  }

  static async patch(input: AtLeast<PatchChannelModel, "id">) {
    const patchUseCase = await locator.get<IocProvider<PatchChannelUseCase>>(TYPES.PatchChannelUseCase)();
    return await withErrorHandler(patchUseCase.execute(input));
  }

  async deleteMany(ids: Id[]) {
    const deleteManyUseCase = await locator.get<IocProvider<DeleteManyChannelUseCase>>(TYPES.DeleteManyChannelUseCase)();
    await withErrorHandler(deleteManyUseCase.execute(ids));
  }

  async export({ filters, ids }: ExportArgumentsModel<FilterChannelModel, SortChannel>) {
    const exportUseCase = await locator.get<IocProvider<ExportChannelUseCase>>(TYPES.ExportRateTypeUseCase)();
    const exportArguments = new ExportArgumentsModel({ filters, ids });
    return await withErrorHandler(exportUseCase.execute(exportArguments));
  }

  static async getAllChannels(item: { name: string }) {
    const filters = new Filters<FilterChannelModel, SortChannel>({ item, size: 100 });
    const getUseCase = await locator.get<IocProvider<ChannelUseCase>>(TYPES.ChannelUseCase)();
    return await withErrorHandler(getUseCase.execute(filters));
  }
}
