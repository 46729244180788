export const TYPES = {
  IEnvVars: Symbol("IEnvVars"),
  VivoltApiService: Symbol("VivoltApiService"),
  VivoltMultipartApiService: Symbol("VivoltMultipartApiService"),
  VivoltStreamApiService: Symbol("VivoltStreamApiService"),
  IAuthTokenService: Symbol("IAuthTokenService"),
  /* USER */
  IAuthRepository: Symbol("IAuthRepository"),
  IProfilesRepository: Symbol("IProfilesRepository"),
  LoginUseCase: Symbol("LoginUseCase"),
  ForgotPasswordUseCase: Symbol("ForgotPasswordUseCase"),
  ResetPasswordUseCase: Symbol("ResetPasswordUseCase"),
  ValidateEmailUseCase: Symbol("ValidateEmailUseCase"),
  LogoutUseCase: Symbol("LogoutUseCase"),
  MeUseCase: Symbol("MeUseCase"),
  PermissionUseCase: Symbol("PermissionUseCase"),
  /* Profiles */
  GetProfilesUseCase: Symbol("GetProfilesUseCase"),
  CreateProfileUseCase: Symbol("CreateProfileUseCase"),
  PatchProfileUseCase: Symbol("PatchProfileUseCase"),
  EditProfileUseCase: Symbol("EditProfileUseCase"),
  GetProfileDetailUseCase: Symbol("GetProfileDetailUseCase"),
  ExportProfileUseCase: Symbol("ExportProfileUseCase"),
  DeleteManyProfilesUseCase: Symbol("DeleteManyProfilesUseCase"),
  GetOperationsUsersUseCase: Symbol("GetOperationsUsersUseCase"),
  /* Rate */
  IRateRepository: Symbol("IRateRepository"),
  GetRatesUseCase: Symbol("GetRatesUseCase"),
  CreateRateUseCase: Symbol("CreateRateUseCase"),
  PatchRateUseCase: Symbol("PatchRateUseCase"),
  EditRateUseCase: Symbol("EditRateUseCase"),
  GetRateDetailUseCase: Symbol("GetRateDetailUseCase"),
  ExportRateUseCase: Symbol("ExportRateUseCase"),
  DeleteManyRateUseCase: Symbol("DeleteManyRateUseCase"),
  /* Rate Types */
  IRateTypeRepository: Symbol("IRateTypeRepository"),
  GetRateTypesUseCase: Symbol("GetRateTypesUseCase"),
  CreateRateTypeUseCase: Symbol("CreateRateTypeUseCase"),
  PatchRateTypeUseCase: Symbol("PatchRateTypeUseCase"),
  EditRateTypeUseCase: Symbol("EditRateTypeUseCase"),
  GetRateTypeDetailUseCase: Symbol("GetRateTypeDetailUseCase"),
  ExportRateTypeUseCase: Symbol("ExportRateTypeUseCase"),
  DeleteManyRateTypeUseCase: Symbol("DeleteManyRateTypeUseCase"),
  /* Energy Cost */
  IEnergyCostRepository: Symbol("IEnergyCostRepository"),
  GetEnergyCostsUseCase: Symbol("GetEnergyCostsUseCase"),
  CreateEnergyCostUseCase: Symbol("CreateEnergyCostUseCase"),
  PatchEnergyCostUseCase: Symbol("PatchEnergyCostUseCase"),
  EditEnergyCostUseCase: Symbol("EditEnergyCostUseCase"),
  GetEnergyCostDetailUseCase: Symbol("GetEnergyCostDetailUseCase"),
  ExportEnergyCostUseCase: Symbol("ExportEnergyCostUseCase"),
  DeleteManyEnergyCostUseCase: Symbol("DeleteManyEnergyCostUseCase"),
  /* Marketer */
  IMarketerRepository: Symbol("IMarketerRepository"),
  GetMarketersUseCase: Symbol("GetMarketersUseCase"),
  CreateMarketerUseCase: Symbol("CreateMarketerUseCase"),
  PatchMarketerUseCase: Symbol("PatchMarketerUseCase"),
  EditMarketerUseCase: Symbol("EditMarketerUseCase"),
  GetMarketerDetailUseCase: Symbol("GetMarketerDetailUseCase"),
  ExportMarketerUseCase: Symbol("ExportMarketerUseCase"),
  DeleteManyMarketerUseCase: Symbol("DeleteManyMark eterUseCase"),
  ExtendRatesMarketerUseCase: Symbol("ExtendRatesMarketerUseCase"),
  /* Marketer Margin */
  IMarketerMarginRepository: Symbol("IMarketerMarginRepository"),
  GetMarketerMarginsUseCase: Symbol("GetMarketerMarginsUseCase"),
  CreateMarketerMarginUseCase: Symbol("CreateMarketerMarginUseCase"),
  PatchMarketerMarginUseCase: Symbol("PatchMarketerMarginUseCase"),
  EditMarketerMarginUseCase: Symbol("EditMarketerMarginUseCase"),
  GetMarketerMarginDetailUseCase: Symbol("GetMarketerMarginDetailUseCase"),
  ExportMarketerMarginUseCase: Symbol("ExportMarketerMarginUseCase"),
  DeleteManyMarketerMarginUseCase: Symbol("DeleteManyMarketerUseCase"),
  /* Cost */
  ICostRepository: Symbol("ICostRepository"),
  GetCostsUseCase: Symbol("GetCostsUseCase"),
  CreateCostUseCase: Symbol("CreateCostUseCase"),
  PatchCostUseCase: Symbol("PatchCostUseCase"),
  EditCostUseCase: Symbol("EditCostUseCase"),
  GetCostDetailUseCase: Symbol("GetCostDetailUseCase"),
  ExportCostUseCase: Symbol("ExportCostUseCase"),
  DeleteManyCostUseCase: Symbol("DeleteManyCostUseCase"),
  /* Commission */
  ICommissionRepository: Symbol("ICommissionRepository"),
  GetCommissionsUseCase: Symbol("GetCommissionsUseCase"),
  CreateCommissionUseCase: Symbol("CreateCommissionUseCase"),
  PatchCommissionUseCase: Symbol("PatchCommissionUseCase"),
  EditCommissionUseCase: Symbol("EditCommissionUseCase"),
  GetCommissionDetailUseCase: Symbol("GetCommissionDetailUseCase"),
  ExportCommissionUseCase: Symbol("ExportCommissionUseCase"),
  DeleteManyCommissionUseCase: Symbol("DeleteManyCommissionUseCase"),
  /* Saving Study */
  ISavingStudyRepository: Symbol("ISavingStudyRepository"),
  GetSavingStudiesUseCase: Symbol("GetSavingStudiesUseCase"),
  CreateSavingStudyUseCase: Symbol("CreateSavingStudyUseCase"),
  PatchSavingStudyUseCase: Symbol("PatchSavingStudyUseCase"),
  EditSavingStudyUseCase: Symbol("EditSavingStudyUseCase"),
  GetSavingStudyDetailUseCase: Symbol("GetSavingStudyDetailUseCase"),
  ExportSavingStudyUseCase: Symbol("ExportSavingStudyUseCase"),
  DeleteManySavingStudyUseCase: Symbol("DeleteManySavingStudyUseCase"),
  GetSipsUseCase: Symbol("GetSipsUseCase"),
  GetSuggestedRatesUseCase: Symbol("GetSuggestedRatesUseCase"),
  GenerateSuggestedRatesUseCase: Symbol("GenerateSuggestedRatesUseCase"),
  FinishSavingStudyUseCase: Symbol("FinishSavingStudyUseCase"),
  EditSuggestedRateUseCase: Symbol("EditSuggestedRateUseCase"),
  DuplicateSavingStudyUseCase: Symbol("DuplicateSavingStudyUseCase"),
  DownloadPDFSavingStudyUseCase: Symbol("DownloadPDFSavingStudyUseCase"),
  /* Client */
  IClientRepository: Symbol("IClientRepository"),
  GetClientsUseCase: Symbol("GetClientsUseCase"),
  CreateClientUseCase: Symbol("CreateClientUseCase"),
  PatchClientUseCase: Symbol("PatchClientUseCase"),
  EditClientUseCase: Symbol("EditClientUseCase"),
  GetClientDetailUseCase: Symbol("GetClientDetailUseCase"),
  ExportClientUseCase: Symbol("ExportClientUseCase"),
  DeleteManyClientUseCase: Symbol("DeleteManyClientUseCase"),
  FinishClientUseCase: Symbol("FinishClientUseCase"),
  DuplicateClientUseCase: Symbol("DuplicateClientUseCase"),
  /* Contact */
  IContactRepository: Symbol("IContactRepository"),
  GetContactsUseCase: Symbol("GetContactsUseCase"),
  CreateContactUseCase: Symbol("CreateContactUseCase"),
  PatchContactUseCase: Symbol("PatchContactUseCase"),
  EditContactUseCase: Symbol("EditContactUseCase"),
  GetContactDetailUseCase: Symbol("GetContactDetailUseCase"),
  ExportContactUseCase: Symbol("ExportContactUseCase"),
  DeleteManyContactUseCase: Symbol("DeleteManyContactUseCase"),
  FinishContactUseCase: Symbol("FinishContactUseCase"),
  DuplicateContactUseCase: Symbol("DuplicateContactUseCase"),
  /* Supply Point */
  ISupplyPointRepository: Symbol("ISupplyPointRepository"),
  GetSupplyPointsUseCase: Symbol("GetSupplyPointsUseCase"),
  CreateSupplyPointUseCase: Symbol("CreateSupplyPointUseCase"),
  PatchSupplyPointUseCase: Symbol("PatchSupplyPointUseCase"),
  EditSupplyPointUseCase: Symbol("EditSupplyPointUseCase"),
  GetSupplyPointDetailUseCase: Symbol("GetSupplyPointDetailUseCase"),
  ExportSupplyPointUseCase: Symbol("ExportSupplyPointUseCase"),
  DeleteManySupplyPointUseCase: Symbol("DeleteManySupplyPointUseCase"),
  FinishSupplyPointUseCase: Symbol("FinishSupplyPointUseCase"),
  DuplicateSupplyPointUseCase: Symbol("DuplicateSupplyPointUseCase"),
  /* Contract */
  IContractRepository: Symbol("IContractRepository"),
  GetContractsUseCase: Symbol("GetContractsUseCase"),
  CreateContractUseCase: Symbol("CreateContractUseCase"),
  PatchContractUseCase: Symbol("PatchContractUseCase"),
  EditContractUseCase: Symbol("EditContractUseCase"),
  GetContractDetailUseCase: Symbol("GetContractDetailUseCase"),
  ExportContractUseCase: Symbol("ExportContractUseCase"),
  DeleteManyContractUseCase: Symbol("DeleteManyContractUseCase"),
  FinishContractUseCase: Symbol("FinishContractUseCase"),
  DuplicateContractUseCase: Symbol("DuplicateContractUseCase"),
  ContractFromStudyUseCase: Symbol("ContractFromStudyUseCase"),
  GetContractsStatsUseCase: Symbol("GetContractsStatsUseCase"),
  GetStatsUseCase: Symbol("GetStatsUseCase"),
  GetContractsFromOperationUseCase: Symbol("GetContractsFromOperationUseCase"),
  EditContractOperationUseCase: Symbol("EditContractOperationUseCase"),
  /* Channel */
  IChannelRepository: Symbol("IChannelRepository"),
  ChannelUseCase: Symbol("ChannelUseCase"),
  GetChannelDetailUseCase: Symbol("GetChannelDetailUseCase"),
  CreateChannelUseCase: Symbol("CreateChannelUseCase"),
  PatchChannelUseCase: Symbol("PatchChannelUseCase"),
  DeleteManyChannelUseCase: Symbol("DeleteManyChannelUseCase"),
  ExportChannelUseCase: Symbol("ExportChannelUseCase"),
  /* Role */
  IRoleRepository: Symbol("IRoleRepository"),
  RoleUseCase: Symbol("RoleUseCase"),
  GetRoleDetailUseCase: Symbol("GetRoleDetailUseCase"),
  CreateRoleUseCase: Symbol("CreateRoleUseCase"),
  PatchRoleUseCase: Symbol("PatchRoleUseCase"),
  DeleteManyRoleUseCase: Symbol("DeleteManyRoleUseCase"),
  ExportRoleUseCase: Symbol("ExportRoleUseCase"),
  RolePermissionUseCase: Symbol("RolePermissionUseCase"),
  /* Document */
  IDocumentRepository: Symbol("IDocumentRepository"),
  IDocumentContractRepository: Symbol("IDocumentContractRepository"),
  GetDocumentsUseCase: Symbol("GetDocumentsUseCase"),
  GetDocumentsContractUseCase: Symbol("GetDocumentsContractUseCase"),
  ExportDocumentsUseCase: Symbol("ExportDocumentsUseCase"),
  GetDocumentDetailUseCase: Symbol("GetDocumentDetailUseCase"),
  GetDocumentDetailContractUseCase: Symbol("GetDocumentDetailContractUseCase"),
  CreateDocumentUseCase: Symbol("CreateDocumentUseCase"),
  CreateBillOcrUseCase: Symbol("CreateBillOcrUseCase"),
  CreateDocumentContractUseCase: Symbol("CreateDocumentContractUseCase"),
  EditDocumentUseCase: Symbol("EditDocumentUseCase"),
  EditDocumentContractUseCase: Symbol("EditDocumentContractUseCase"),
  DeleteManyDocumentUseCase: Symbol("DeleteManyDocumentUseCase"),
  DeleteManyDocumentContractUseCase: Symbol("DeleteManyDocumentContractUseCase"),
  IDocumentSupplyPointRepository: Symbol("IDocumentSupplyPointRepository"),
  GetDocumentsSupplyPointUseCase: Symbol("GetDocumentsSupplyPointUseCase"),
  GetDocumentDetailSupplyPointUseCase: Symbol("GetDocumentDetailSupplyPointUseCase"),
  DeleteManyDocumentSupplyPointUseCase: Symbol("DeleteManyDocumentSupplyPointUseCase"),
  EditDocumentSupplyPointUseCase: Symbol("EditDocumentSupplyPointUseCase"),
  CreateDocumentSupplyPointUseCase: Symbol("CreateDocumentSupplyPointUseCase"),
  GetDownloadDocumentUseCase: Symbol("GetDownloadDocumentUseCase"),
  GetDownloadContractDocumentUseCase: Symbol("GetDownloadContractDocumentUseCase"),
  GetDownloadSupplyPointDocumentUseCase: Symbol("GetDownloadSupplyPointDocumentUseCase"),
  /* Settlement */
  ISettlementRepository: Symbol("ISettlementRepository"),
  GetSettlementsUseCase: Symbol("GetSettlementsUseCase"),
  DeleteManySettlementsUseCase: Symbol("DeleteManySettlementsUseCase"),
  ExportSettlementsUseCase: Symbol("ExportSettlementsUseCase"),
  GetSettlementDetailUseCase: Symbol("GetSettlementDetailUseCase")
};
