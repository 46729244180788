import type { Id } from "@/src/common/utils/types";
import { createProvider } from "@/src/common/utils/zustand";
import type { CreateDocumentSupplyPointModel } from "@/src/core/document/domain/models/create_document_supply_point_model";
import DocumentSupplyPointController from "@/src/ui/pages/document/controllers/document_supply_point_controller";
import { getMutationInitialState } from "@/src/ui/provider/mutation.slice";
import type { MutationState } from "@/src/ui/view_models/mutation_state";
import type { DetailDocumentSupplyPointModel } from "@/src/core/document/domain/models/detail_document_supply_point_model";

export const useMutationDocumentSupplyPointProvider = createProvider<MutationState<DetailDocumentSupplyPointModel, CreateDocumentSupplyPointModel>>(
  () => (set) => ({
    async edit(input: CreateDocumentSupplyPointModel, id: Id) {
      const response = await DocumentSupplyPointController.edit(input, id);
      set({ item: response });
      return response;
    },
    ...getMutationInitialState(set, new DocumentSupplyPointController())
  })
);
