import { errorProvider } from "@/src/ui/provider/error.provider";
import type { ApiError } from "../interfaces/api_error_model";
import type { IRestDataError } from "../interfaces/rest_data_source";
import errorTranslations from "@/src/ui/i18n/locales/es/error.json";
import { t } from "i18next";

export async function withErrorHandler<T>(promise: Promise<T>) {
  try {
    return await promise;
  } catch (e) {
    console.error(e);
    if (Array.isArray(e)) {
      const error = (e as ApiError[])[0];
      const isTranslatedCode = Object.keys(errorTranslations).includes(error.code);

      const message: string = isTranslatedCode ? t("error:" + error.code) : t("error:DEFAULT");

      const modifiedErrors = (e as ApiError[]).map((err) => {
        return {
          ...err,
          message: message
        };
      });

      errorProvider.getState().setErrors(modifiedErrors);
    } else {
      const error: ApiError = {
        code: "",
        field: "",
        message: "",
        source: ""
      };
      errorProvider.getState().setErrors([error]);
    }
    throw e;
  }
}

export function handleResponseError(responseError: IRestDataError): ApiError[] {
  const errorMessage = responseError?.response?.data?.detail;
  if (!errorMessage) {
    const serverError: ApiError = {
      code: "default",
      source: null,
      field: null,
      message: "server error"
    };
    return [serverError];
  }

  return errorMessage;
}
