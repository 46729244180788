import { createProvider } from "@/src/common/utils/zustand";
import SettlementController from "../controllers/settlement_controller";
import type { Id } from "@/src/common/utils/types";
import type { SettlementModel } from "@/src/core/settlement/domain/models/settlement_model";

interface Props {
  getSettlementDetails: (id?: Id) => void;
  details: SettlementModel | null;
}

export const useSettlementDetailsProvider = createProvider<Props>(() => {
  return (set) => ({
    details: null,
    async getSettlementDetails(id?: Id) {
      try {
        if (!id) return;
        const response = await new SettlementController().getOneById(id);
        set({ details: response });
      } catch {
        set({ details: null });
      }
    }
  });
});
