import { useTranslation } from "react-i18next";
import type { ModalData } from "@/src/ui/components/sure_modal/sure_modal";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useShowToast from "@/src/ui/hooks/useShowToast";
import { useMutationRoleProvider } from "../../provider/mutation_role.provider";
import paths from "@/src/ui/router/paths";
import { useEffectRunOnce } from "@front_web_mrmilu/hooks";
import type { Id } from "@/src/common/utils/types";
import { Delete, Key } from "@/src/ui/assets/icons";
import Styled from "./detail_role_page.styled";
import DetailPageHeader from "@/src/ui/components/detail_page_header/detail_page_header";
import DetailRole from "../../components/detail_role/detail_role";

export default function DetailRolePage() {
  const { t } = useTranslation(["role", "common"]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData>();
  const navigate = useNavigate();
  const { showToast } = useShowToast();
  const getRoleById = useMutationRoleProvider((state) => state.getById);
  const role = useMutationRoleProvider((state) => state.item);
  const { roleId } = useParams();

  const onCloseModal = useCallback(() => setShowModal(false), []);

  const getRole = useCallback(async () => {
    if (roleId) {
      const id = Number(roleId);
      const response = await getRoleById(id);
      if (!response) {
        navigate(paths.role.index);
      }
    }
  }, [getRoleById, roleId, navigate]);

  useEffectRunOnce(() => {
    getRole();
  }, [getRole]);

  const onClickDelete = useCallback(
    (id: Id) => {
      setShowModal(true);

      const newModalData: ModalData = {
        description: t("role:sure.delete"),
        primaryModalButtonText: t("common:actions.remove"),
        onClickPrimaryButton: async () => {
          console.log(id);
          //await ContactController.delete(id);
          onCloseModal();
          navigate(paths.role.index);
          showToast({ message: t("role:actions.deleted") });
        }
      };
      setModalData(newModalData);
    },
    [navigate, onCloseModal, t, showToast]
  );

  const actions = useMemo(
    () =>
      role
        ? [
            {
              Icon: Delete,
              text: t("role:actions.delete", { ns: "role" }),
              onClick: () => onClickDelete(Number(roleId))
            }
          ]
        : [],
    [role, onClickDelete, roleId, t]
  );

  return (
    <Styled.Page>
      <DetailPageHeader
        Icon={Key}
        resourcePath={paths.role.index}
        resourceName={t("role:roles")}
        headerText={role ? role.name : ""}
        creationInformation={undefined}
        actions={actions}
        showModal={showModal}
        onCloseModal={onCloseModal}
        modalPrimaryButtonText={modalData?.primaryModalButtonText}
        modalDescription={modalData?.description}
        onPrimaryButtonClick={modalData?.onClickPrimaryButton}
        showBreadcrumbs={true}
      />
      <DetailRole role={role} />
    </Styled.Page>
  );
}
