/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormikProvider } from "formik";
import { useTranslation } from "react-i18next";
import Styled from "./edit_channel.styled";
import { InputFormik } from "@/src/ui/components/input/input";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useShowToast from "@/src/ui/hooks/useShowToast";
import { useEffectRunOnce } from "@front_web_mrmilu/hooks";
import paths from "@/src/ui/router/paths";
import { useCallback, useMemo } from "react";
import { useMutationChannelProvider } from "../../provider/mutation_channel.provider";
import type { CreateChannelModel } from "@/src/core/channel/domain/models/create_channel_model";
import useEditChannelForm from "../../controllers/edit_channel_controller";

const useEditContactInitialValues = () => {
  const channel = useMutationChannelProvider((state) => state.item);
  return useMemo(() => {
    if (!channel) return undefined;

    return {
      ...channel
    };
  }, [channel]);
};

export default function EditChannel() {
  const { channelId } = useParams();
  const ediChannel = useMutationChannelProvider((state) => state.edit);
  const getChannelById = useMutationChannelProvider((state) => state.getById);
  const channel = useMutationChannelProvider((state) => state.item);
  const { showToast } = useShowToast();
  const navigate = useNavigate();
  const { t } = useTranslation(["channel", "common"]);
  const initialValues = useEditContactInitialValues();

  const onSubmitForm = async (input: CreateChannelModel) => {
    if (!channel) throw new Error("Channel it's null");
    await ediChannel(input, Number(channelId));
    navigate(-1);
    showToast({ message: t("channel:actions.edited") });
  };

  const { formSchema, isFormEmpty } = useEditChannelForm({
    onSubmitForm,
    initialValues,
    id: Number(channelId)
  });

  const getChannel = useCallback(async () => {
    if (channelId) {
      const id = Number(channelId);
      const response = await getChannelById(id);
      if (!response) {
        navigate(paths.channel.index);
      }
    }
  }, [getChannelById, navigate, channelId]);

  useEffectRunOnce(() => {
    getChannel();
  }, [getChannel]);

  return (
    <>
      <Styled.Header>
        <h1>{t("channel:actions.edit")}</h1>
        <p>{t("common:editDescription")}</p>
      </Styled.Header>
      <FormikProvider value={formSchema}>
        <Form noValidate autoComplete="off" placeholder={""}>
          <Styled.ChannelData>
            <Styled.FormGrid>
              <InputFormik name="name" label={t("channel:columns.name")} id="name" />
              <InputFormik name="socialName" label={t("channel:columns.socialName")} id="socialName" />
              <InputFormik name="cif" label={t("channel:columns.cif")} id="cif" />
            </Styled.FormGrid>
          </Styled.ChannelData>
          <Styled.ChannelData>
            <Styled.FormGrid>
              <InputFormik name="bankAccountHolder" label={t("channel:columns.bankAccountHolder")} id="bankAccountHolder" />
              <InputFormik name="bankAccountNumber" label={t("channel:columns.bankAccountNumber")} id="bankAccountNumber" />
              <InputFormik name="fiscalAddress" label={t("channel:columns.fiscalAddress")} id="fiscalAddress" />
              <InputFormik name="fee" label={t("channel:columns.fee")} id="fee" type="number" decimals={2} />
            </Styled.FormGrid>
          </Styled.ChannelData>

          <Styled.Button>
            <Button variant="contained" disabled={isFormEmpty} type="submit">
              {t("common:actions.save")}
            </Button>
          </Styled.Button>
        </Form>
      </FormikProvider>
    </>
  );
}
