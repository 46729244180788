import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import paths, { WILDCARD_PATH } from "@/src/ui/router/paths";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { lazy, useEffect, useState } from "react";
import { useListSettlementProvider } from "@/src/ui/pages/settlement/provider/list_settlement_provider";
import { useSettlementDetailsProvider } from "@/src/ui/pages/settlement/provider/mutation_settlement.provider";
import { useListMarketerProvider } from "../../marketer/views/marketer_page/provider/list_marketer.provider";

const ListSettlementPage = lazy(() => import("@/src/ui/pages/settlement/views/settlement_page/settlement_page"));
const DetailSettlementPage = lazy(() => import("@/src/ui/pages/settlement/views/detail_settlement_page/detail_settlement_page"));
const ID_PATH_PARAM = ":settlementId";

export default function SettlementPages() {
  const navigate = useNavigate();

  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    const list = [];
    list.push(
      {
        index: true,
        element: (
          <useListSettlementProvider.State>
            <useListMarketerProvider.State>
              <ListSettlementPage />
            </useListMarketerProvider.State>
          </useListSettlementProvider.State>
        )
      },
      {
        path: paths.rate.detail + ID_PATH_PARAM,
        element: (
          <useSettlementDetailsProvider.State>
            <DetailSettlementPage />
          </useSettlementDetailsProvider.State>
        )
      },
      { path: WILDCARD_PATH, element: <Navigate to={paths.supplyPoint.index} replace /> }
    );
    setRouteList(list);
  }, [navigate]);

  const page = useRoutes(routeList);
  return <AppErrorBoundary key="supply_point-error">{page}</AppErrorBoundary>;
}
