import { useNavigate, useRoutes } from "react-router-dom";
import paths from "@/src/ui/router/paths";
import { useMutationDocumentProvider } from "@/src/ui/pages/document/provider/mutation_document.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { RootState, UserPermissions } from "@/src/redux/store";

const CreateDocumentPage = lazy(() => import("@/src/ui/pages/document/views/create_document_page/create_document_page"));
const EditDocumentPage = lazy(() => import("@/src/ui/pages/document/views/edit_document_page/edit_document_page"));

const ID_PATH_PARAM = ":documentId";

export default function DocumentPages() {
  const navigate = useNavigate();
  const userPermissions = useSelector((state: RootState) => state.app.userPermissions as unknown as UserPermissions);
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    if (userPermissions) {
      const list = [];
      list.push(
        {
          path: paths.document.create,
          element: <CreateDocumentPage />
        },
        {
          path: paths.document.createSavingStudy,
          element: <CreateDocumentPage mode={paths.savingStudy.index} />
        },
        {
          path: paths.document.edit + ID_PATH_PARAM,
          element: <EditDocumentPage />
        }
      );
      setRouteList(list);
    }
  }, [userPermissions, navigate]);

  const page = useRoutes(routeList);

  return (
    <AppErrorBoundary key="document-error">
      <useMutationDocumentProvider.State>{page}</useMutationDocumentProvider.State>
    </AppErrorBoundary>
  );
}
