import type { ListController } from "@/src/common/interfaces/list_controller";
import type { MutationController } from "@/src/common/interfaces/mutation_controller";
import type { SortRole } from "@/src/core/role/domain/interfaces/sort_role";
import type { RoleModel } from "@/src/core/role/domain/models/role_model";
import type { FilterRoleModel } from "@/src/core/role/domain/models/filter_role_model";
import { Filters } from "@/src/core/app/domain/models/filters";
import type { AtLeast, Id } from "@/src/common/utils/types";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RoleUseCase } from "@/src/core/role/domain/use_cases/role_use_case";
import { withErrorHandler } from "@/src/common/utils/errors";
import type { GetRoleDetailUseCase } from "@/src/core/role/domain/use_cases/get_role_detail_use_case";
import type { CreateRoleModel } from "@/src/core/role/domain/models/create_role_model";
import type { CreateRoleUseCase } from "@/src/core/role/domain/use_cases/create_role_use_case";
import type { PatchRoleUseCase } from "@/src/core/role/domain/use_cases/patch_role_use_case";
import type { PatchRoleModel } from "@/src/core/role/domain/models/patch_role_model";
import { ExportArgumentsModel } from "@/src/core/app/domain/models/export_argument";
import type { DeleteManyRoleUseCase } from "@/src/core/role/domain/use_cases/delete_many_role_use_case";
import type { ExportRoleUseCase } from "@/src/core/role/domain/use_cases/export_role_use_case";
import type { RolePermissionUseCase } from "@/src/core/role/domain/use_cases/role_permission_use_case";

export default class RoleController implements ListController<RoleModel, FilterRoleModel, SortRole>, MutationController<RoleModel> {
  async getAll(filter: Filters<FilterRoleModel, SortRole>) {
    const getUseCase = await locator.get<IocProvider<RoleUseCase>>(TYPES.RoleUseCase)();
    return await withErrorHandler(getUseCase.execute(filter));
  }

  async getOneById(id: Id) {
    const getDetailUseCase = await locator.get<IocProvider<GetRoleDetailUseCase>>(TYPES.GetRoleDetailUseCase)();
    return await withErrorHandler(getDetailUseCase.execute(id));
  }

  static async create(input: CreateRoleModel) {
    const createRoleUseCase = await locator.get<IocProvider<CreateRoleUseCase>>(TYPES.CreateRoleUseCase)();
    await withErrorHandler(createRoleUseCase.execute(input));
  }

  static async delete(id: Id) {
    const patchUseCase = await locator.get<IocProvider<PatchRoleUseCase>>(TYPES.PatchRoleUseCase)();
    await withErrorHandler(patchUseCase.delete(id));
  }

  static async patch(input: AtLeast<PatchRoleModel, "id">) {
    const patchUseCase = await locator.get<IocProvider<PatchRoleUseCase>>(TYPES.PatchRoleUseCase)();
    return await withErrorHandler(patchUseCase.execute(input));
  }

  async deleteMany(ids: Id[]) {
    const deleteManyUseCase = await locator.get<IocProvider<DeleteManyRoleUseCase>>(TYPES.DeleteManyRoleUseCase)();
    await withErrorHandler(deleteManyUseCase.execute(ids));
  }

  async export({ filters, ids }: ExportArgumentsModel<FilterRoleModel, SortRole>) {
    const exportUseCase = await locator.get<IocProvider<ExportRoleUseCase>>(TYPES.ExportRoleUseCase)();
    const exportArguments = new ExportArgumentsModel({ filters, ids });
    return await withErrorHandler(exportUseCase.execute(exportArguments));
  }

  static async getAllRoles(item: FilterRoleModel) {
    const filters = new Filters<FilterRoleModel, SortRole>({ item });
    const getUseCase = await locator.get<IocProvider<RoleUseCase>>(TYPES.RoleUseCase)();
    return await withErrorHandler(getUseCase.execute(filters));
  }

  static async defaultRolePermissions() {
    const rolePermissionUseCase = await locator.get<IocProvider<RolePermissionUseCase>>(TYPES.RolePermissionUseCase)();
    return await withErrorHandler(rolePermissionUseCase.defaultRolePermission());
  }

  static async rolePermissions(id: Id) {
    const rolePermissionUseCase = await locator.get<IocProvider<RolePermissionUseCase>>(TYPES.RolePermissionUseCase)();
    return await withErrorHandler(rolePermissionUseCase.rolePermission(id));
  }
}
