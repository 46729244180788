import { Eye, Hide } from "@/src/ui/assets/icons";
import { InputFormik } from "@/src/ui/components/input/input";
import { Button } from "@mui/material";
import { Form, FormikProvider } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordChecker from "../password_checker/password_checker";
import Styled from "./validate_email_form.styled";
import type { ValidateEmailModel } from "@/src/core/user/domain/models/validate_email_model";
import useValidateEmailController from "../../pages/profile/controllers/validate_email_controller";

interface Props {
  title: string;
  subtitle: string;
  submitText: string;
  onSubmitForm: (input: ValidateEmailModel) => Promise<void>;
}

export default function ValidateEmailForm({ title, subtitle, submitText, onSubmitForm }: Props) {
  const { t } = useTranslation("validate_email");
  const { formSchema } = useValidateEmailController({ onSubmitForm });
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const onClickPasswordIcon = useCallback(() => {
    setIsPasswordShown((prevState) => !prevState);
  }, []);

  return (
    <>
      <FormikProvider value={formSchema}>
        <h1>{title}</h1>
        <p>{subtitle}</p>
        <Form placeholder={""}>
          <InputFormik
            onClickIcon={onClickPasswordIcon}
            type={isPasswordShown ? "text" : "password"}
            name="password"
            label={t("form.fields.password.placeholder")}
            icon={isPasswordShown ? <Hide /> : <Eye />}
          />
          <Styled.ButtonWrapper>
            <Button type="submit" variant="contained" disabled={formSchema.isSubmitting}>
              {submitText}
            </Button>
          </Styled.ButtonWrapper>
        </Form>
      </FormikProvider>
      <PasswordChecker password={formSchema.values.password} />
    </>
  );
}
