import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  userPermissions: null,
  channel: undefined
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload;
      return state;
    },
    removeUserInfo: (state) => {
      state.user = null;
      return state;
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
      return state;
    },
    removeUserPermissions: (state) => {
      state.userPermissions = null;
      return state;
    },
    setChannel: (state, action) => {
      state.channel = action.payload;
      return state;
    },
    removeChannel: (state) => {
      state.channel = undefined;
      return state;
    }
  }
});

export const { setUserInfo, removeUserInfo, setUserPermissions, removeUserPermissions, setChannel, removeChannel } = appSlice.actions;

export default appSlice.reducer;
