import { useTranslation } from "react-i18next";
import DetailPageHeader from "@/src/ui/components/detail_page_header/detail_page_header";
import { DeviceHub } from "@/src/ui/assets/icons";
import useCancelButton from "@/src/ui/hooks/useCancelButton";
import { useMutationChannelProvider } from "@/src/ui/pages/channel/provider/mutation_channel.provider";
import EditChannel from "../../components/edit_channel/edit_channel";

export default function EditChannelPage() {
  const { t } = useTranslation(["channel", "common"]);
  const { showModal, onCloseModal, onCancel, cancelAction } = useCancelButton({ condition: true });
  const channel = useMutationChannelProvider((state) => state.item);

  return (
    <>
      <DetailPageHeader
        Icon={DeviceHub}
        headerText={channel?.name || ""}
        actions={cancelAction}
        showModal={showModal}
        modalPrimaryButtonText={t("common:keepEditing")}
        modalDescription={t("common:cancelDescription")}
        onSecondaryButtonClick={onCancel}
        onPrimaryButtonClick={onCloseModal}
      />
      <EditChannel />
    </>
  );
}
