import { createProvider } from "@/src/common/utils/zustand";
import SupplyPointController from "@/src/ui/pages/supply_point/controllers/supply_point_controller";
import type { AutocompleteState } from "@/src/ui/view_models/autocomplete_state";
import { getAutocompleteInitialState } from "@/src/ui/provider/autocomplete.slice";
import type { Id } from "@/src/common/utils/types";
import type { EnergyTypes } from "@/src/core/app/enums/energy_types";
import type { DetailSupplyPointModel } from "@/src/core/supply_point/domain/models/detail_supply_point_model";

interface AutocompleteProviderBuilderProps {
  filterByActiveSupplyPoints?: boolean;
  client?: Id;
  energyType?: EnergyTypes;
  id?: Id;
}

interface Props extends AutocompleteState {
  client?: Id;
  energyType?: EnergyTypes;
  filterByActiveSupplyPoints?: boolean;
  setClient: (client?: Id) => void;
  setEnergyType: (energyType?: EnergyTypes) => void;
  getSupplyPointDetails: (id?: Id) => void;
  details: DetailSupplyPointModel | null;
}

export const useAutocompleteSupplyPointsProvider = createProvider<Props, AutocompleteProviderBuilderProps>(
  ({ filterByActiveSupplyPoints, client, energyType, id }) => {
    return (set, get) => ({
      ...getAutocompleteInitialState(get, set),
      client,
      setClient(client) {
        set({ client });
      },
      energyType,
      setEnergyType(energyType) {
        set({ energyType });
      },
      filterByActiveSupplyPoints,
      async getByName() {
        set({ isLoading: true });
        try {
          const response = await SupplyPointController.getAllByName({
            cups: get().filterName,
            clientId: get().client,
            energyType: get().energyType
          });
          set({
            items: response.items.map((SupplyPoint) => ({
              label: SupplyPoint.cups,
              id: SupplyPoint.id,
              energyType: SupplyPoint.energyType
            }))
          });
        } catch {
        } finally {
          set({ isLoading: false });
        }
      },
      id,
      async getSupplyPointDetails(id?: Id) {
        try {
          if (!id) return;
          const response = await new SupplyPointController().getOneById(id);
          set({ details: response });
        } catch {
          set({ details: null });
        }
      },
      details: null
    });
  }
);
