import { withErrorHandler } from "@/src/common/utils/errors";
import { ValidateEmailModel } from "@/src/core/user/domain/models/validate_email_model";
import ValidateEmailForm from "@/src/ui/components/validate_email_form/validate_email_form";
import UserController from "@/src/ui/controller/user_controller";
import Styled from "@/src/ui/pages/auth/components/auth_form/auth_form.styled";
import AuthPage from "@/src/ui/pages/auth/components/auth_page/auth_page";
import paths from "@/src/ui/router/paths";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function ValidateEmailPage() {
  const { t } = useTranslation("validate_email");
  const { userId, token } = useParams();
  const navigate = useNavigate();

  const onValidateEmail = async (input: ValidateEmailModel) => {
    if (token) {
      const password = (input as ValidateEmailModel).password;
      const data = new ValidateEmailModel({ password, userId: userId, token: token });
      await withErrorHandler(UserController.validateEmail(data));
      navigate(paths.auth.login);
    }
  };
  return (
    <AuthPage>
      <Styled.AuthForm>
        <ValidateEmailForm onSubmitForm={onValidateEmail} title={t("title")} subtitle={t("subtitle")} submitText={t("form.submit")} />
      </Styled.AuthForm>
    </AuthPage>
  );
}
