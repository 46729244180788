import type { ValidateEmailModel } from "@/src/core/user/domain/models/validate_email_model";
import { yupRequiredPasswordField } from "@/src/ui/utils/yup";
import { yup } from "@front_web_mrmilu/utils";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";

interface FormValidateEmailValues {
  password: string;
}

const formValues: FormValidateEmailValues = {
  password: ""
};

interface Props {
  onSubmitForm: (input: ValidateEmailModel) => Promise<void>;
}

const passwordRequired = yupRequiredPasswordField();

export default function useValidateEmailController({ onSubmitForm }: Props) {
  const [firstSubmit] = useState(false);

  const onSubmit = useCallback(
    async (values: FormValidateEmailValues, { setSubmitting }: FormikHelpers<FormValidateEmailValues>) => {
      setSubmitting(false);
      await onSubmitForm({ password: values.password } as ValidateEmailModel);
    },
    [onSubmitForm]
  );

  const validationSchema = useMemo(() => {
    return yup.object({
      password: passwordRequired
    });
  }, []);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    validateOnBlur: firstSubmit,
    validateOnChange: firstSubmit,
    onSubmit
  });

  const isFormEmpty = useMemo(() => Object.values(formik.values).reduce((isEmpty, value) => isEmpty && !value.length, true), [formik.values]);

  return { formSchema: formik, isFormEmpty };
}
