import type { Filters } from "@/src/core/app/domain/models/filters";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { FilterSettlementModel } from "@/src/core/settlement/domain/models/filter_settlement_model";
import type { SortSettlement } from "@/src/core/settlement/domain/interfaces/sort_settlement";
import type { Id } from "@/src/common/utils/types";
import type { ListController } from "@/src/common/interfaces/list_controller";
import type { SettlementModel } from "@/src/core/settlement/domain/models/settlement_model";
import type { MutationController } from "@/src/common/interfaces/mutation_controller";
import type { GetSettlementsUseCase } from "@/src/core/settlement/domain/use_cases/get_settlements_use_case";
import { withErrorHandler } from "@/src/common/utils/errors";
import { ExportArgumentsModel } from "@/src/core/app/domain/models/export_argument";
import type { DeleteManySettlementsUseCase } from "@/src/core/settlement/domain/use_cases/delete_many_settlements_use_case";
import type { ExportSettlementsUseCase } from "@/src/core/settlement/domain/use_cases/export_settlements_use_case";
import type { GetSettlementDetailUseCase } from "@/src/core/settlement/domain/use_cases/get_settlement_details_use_case";

export default class SettlementController
  implements ListController<SettlementModel, FilterSettlementModel, SortSettlement>, MutationController<SettlementModel>
{
  async getOneById(id: Id) {
    const getSettlementUseCase = await locator.get<IocProvider<GetSettlementDetailUseCase>>(TYPES.GetSettlementDetailUseCase)();
    return await withErrorHandler(getSettlementUseCase.execute(id));
  }

  async getAll(filter: Filters<FilterSettlementModel, SortSettlement>) {
    const settlementsUseCase = await locator.get<IocProvider<GetSettlementsUseCase>>(TYPES.GetSettlementsUseCase)();
    return await withErrorHandler(settlementsUseCase.execute(filter));
  }

  async deleteMany(ids: Id[]) {
    const deleteManyUseCase = await locator.get<IocProvider<DeleteManySettlementsUseCase>>(TYPES.DeleteManySettlementsUseCase)();
    await withErrorHandler(deleteManyUseCase.execute(ids));
  }

  async export({ filters, ids }: ExportArgumentsModel<FilterSettlementModel, SortSettlement>) {
    const exportUseCase = await locator.get<IocProvider<ExportSettlementsUseCase>>(TYPES.ExportSettlementsUseCase)();
    const exportArguments = new ExportArgumentsModel({ filters, ids });
    return await withErrorHandler(exportUseCase.execute(exportArguments));
  }
}
