import { colors } from "@/src/ui/styles/colors";
import { spacing } from "@/src/ui/styles/spacing";
import { formContainerGrid, px2rem } from "@/src/ui/styles/utils";
import styled from "styled-components";

const SinglePermissionCheckbox = styled.div`
  border: ${px2rem(1)} solid ${colors["grayscale-silver-subtle"]};
  padding: ${px2rem(spacing.size4)};

  & > h2 {
    padding-bottom: ${px2rem(spacing.size6)};
  }

  & > div {
    ${formContainerGrid};
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default { SinglePermissionCheckbox };
