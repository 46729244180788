import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import paths, { WILDCARD_PATH } from "@/src/ui/router/paths";
import { useMutationRateProvider } from "@/src/ui/pages/rate/provider/mutation_rate.provider";
import { useAutocompleteRateTypesProvider } from "@/src/ui/provider/autocomplete_rate_types.provider";
import { useListRateProvider } from "@/src/ui/pages/rate/provider/list_rate.provider";
import { useAutocompleteMarketersProvider } from "@/src/ui/pages/rate/provider/autocomplete_marketer.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { EnergyTypes } from "@/src/core/app/enums/energy_types";
import { useSelector } from "react-redux";
import type { RootState, UserPermissions } from "@/src/redux/store";
import { useListRateTypeProvider } from "@/src/ui/pages/rate_type/views/rate_type_page/provider/list_rate_type.provider";
import { useListMarketerProvider } from "@/src/ui/pages/marketer/views/marketer_page/provider/list_marketer.provider";

const CreateRatePage = lazy(() => import("@/src/ui/pages/rate/views/create_rate_page/create_rate_page"));
const DetailRatePage = lazy(() => import("@/src/ui/pages/rate/views/detail_rate_page/detail_rate_page"));
const EditRatePage = lazy(() => import("@/src/ui/pages/rate/views/edit_rate_page/edit_rate_page"));
const ListGasRatesPage = lazy(() => import("@/src/ui/pages/rate/views/list_gas_rate_page/list_gas_rate_page"));
const ListLightRatesPage = lazy(() => import("@/src/ui/pages/rate/views/list_light_rate_page/list_light_rate_page"));

const ID_PATH_PARAM = ":rateId";

export default function RatePages() {
  const navigate = useNavigate();
  const userPermissions = useSelector((state: RootState) => state.app.userPermissions as unknown as UserPermissions);
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    if (userPermissions) {
      if (userPermissions && userPermissions["rates"] && userPermissions["rates"].list) {
        let list = [];
        list.push(
          {
            path: paths.rate.gas,
            element: (
              <useListRateTypeProvider.State>
                <useListMarketerProvider.State>
                  <useListRateProvider.State
                    key={EnergyTypes.GAS}
                    builderProps={{
                      filters: {
                        energyType: EnergyTypes.GAS
                      }
                    }}
                  >
                    <ListGasRatesPage />
                  </useListRateProvider.State>
                </useListMarketerProvider.State>
              </useListRateTypeProvider.State>
            )
          },
          {
            path: paths.rate.light,
            element: (
              <useListRateTypeProvider.State>
                <useListMarketerProvider.State>
                  <useListRateProvider.State
                    key={EnergyTypes.LIGHT}
                    builderProps={{
                      filters: {
                        energyType: EnergyTypes.LIGHT
                      }
                    }}
                  >
                    <ListLightRatesPage />
                  </useListRateProvider.State>
                </useListMarketerProvider.State>
              </useListRateTypeProvider.State>
            )
          },
          {
            path: paths.rate.detail + ID_PATH_PARAM,
            element: <DetailRatePage />
          },
          { path: WILDCARD_PATH, element: <Navigate to={paths.rate.create} replace /> }
        );

        if (userPermissions["rates"].create) {
          list = list.concat([
            {
              path: paths.rate.create,
              element: (
                <useAutocompleteMarketersProvider.State builderProps={{ filterByActiveMarketers: true }}>
                  <useAutocompleteRateTypesProvider.State
                    builderProps={{
                      filterByActiveRateTypes: true
                    }}
                  >
                    <CreateRatePage />
                  </useAutocompleteRateTypesProvider.State>
                </useAutocompleteMarketersProvider.State>
              )
            },
            {
              path: paths.rate.edit + ID_PATH_PARAM,
              element: (
                <useAutocompleteMarketersProvider.State builderProps={{ filterByActiveMarketers: true }}>
                  <useAutocompleteRateTypesProvider.State
                    builderProps={{
                      filterByActiveRateTypes: true
                    }}
                  >
                    <EditRatePage />
                  </useAutocompleteRateTypesProvider.State>
                </useAutocompleteMarketersProvider.State>
              )
            }
          ]);
        }
        setRouteList(list);
      } else {
        navigate("/");
      }
    }
  }, [userPermissions, navigate]);

  const page = useRoutes(routeList);
  return (
    <AppErrorBoundary key="rate-error">
      <useMutationRateProvider.State>{page}</useMutationRateProvider.State>
    </AppErrorBoundary>
  );
}
