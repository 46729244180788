import styled from "styled-components";
import { px2rem } from "@/src/ui/styles/utils";
import { SIDEBAR_WIDTH } from "@/src/ui/styles/spacing";

const Wrapper = styled.div`
  padding: ${px2rem(SIDEBAR_WIDTH)} 0 0 ${px2rem(SIDEBAR_WIDTH)};

  margin: auto;
  min-height: 100vh;
  margin-left: 40px;
  margin-right: 40px;
`;

const BaseLayoutStyled = {
  Wrapper
};

export default BaseLayoutStyled;
