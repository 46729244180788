import type { Id } from "@/src/common/utils/types";
import useFormController from "@/src/ui/hooks/useFormController";
import { yupRequiredField } from "@/src/ui/utils/yup";
import { yup } from "@front_web_mrmilu/utils";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import type { ObjectShape } from "yup/lib/object";
import { removeNullishValuesFromAnObject } from "@/src/common/utils";
import type { CreateRoleModel } from "@/src/core/role/domain/models/create_role_model";

interface FormEditRoleValues {
  id?: Id;
  name: string;
}

const formValues: FormEditRoleValues = {
  id: undefined,
  name: ""
};

interface Props {
  onSubmitForm: (input: CreateRoleModel) => Promise<void>;
  initialValues?: FormEditRoleValues;
  id?: Id;
}

const nameRequired = yupRequiredField();

export default function useEditRoleForm({ onSubmitForm, initialValues = formValues, id }: Props) {
  const [firstSubmit] = useState(false);

  const validationSchema = useMemo(() => {
    const yupObject: ObjectShape = {
      name: nameRequired
    };

    return yup.object(yupObject);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: firstSubmit,
    validateOnChange: firstSubmit,
    onSubmit: async (values: FormEditRoleValues, { setSubmitting }: FormikHelpers<FormEditRoleValues>) => {
      setSubmitting(false);
      await onSubmitForm(removeNullishValuesFromAnObject(values) as CreateRoleModel);
    }
  });

  const { isFormEmpty } = useFormController({ formik, id, initialValues });

  return { formSchema: formik, isFormEmpty };
}
