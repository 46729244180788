import { createProvider } from "@/src/common/utils/zustand";
import ContractController from "../../contract/controllers/contract_controller";
import type { ContractStatsModel } from "@/src/core/contract/domain/models/contract_stats_model";
import type { StatsModel } from "@/src/core/contract/domain/models/stats_model";

const controller = new ContractController();

interface ListStatsState {
  isLoading: boolean;
  getStats(): Promise<void>;
  rows: ContractStatsModel[];
  counterStats(): Promise<void>;
  totals: StatsModel | undefined;
}

export const useContractStatsProvider = createProvider<ListStatsState>(() => (set) => ({
  isLoading: false,
  rows: [],
  totals: undefined,
  async getStats() {
    set({ isLoading: true });
    try {
      const response = await controller.stats();
      set({ rows: response });
    } catch (e) {
    } finally {
      set({ isLoading: false });
    }
  },
  async counterStats() {
    try {
      const response = await controller.statsCounter();
      set({ totals: response });
    } catch (e) {
    } finally {
    }
  }
}));
