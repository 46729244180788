export const spacing = {
  size1: 4,
  size2: 8,
  size3: 12,
  size4: 16,
  size5: 20,
  size6: 24,
  size7: 32,
  size8: 40,
  size9: 48,
  size10: 56,
  size11: 64,
  size12: 80
};

export const SIDEBAR_WIDTH = 72;
export const SIDEBAR_OPEN_WIDTH = 340;
export const BACKOFFICE_MAX_WIDTH = 1275;
