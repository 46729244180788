import type { Optional } from "@/src/common/utils/types";
import FakeInput from "@/src/ui/components/fake_input/fake_input";
import LinkAsButton from "@/src/ui/components/LinkAsButton/link_as_button";
import paths from "@/src/ui/router/paths";
import { useTranslation } from "react-i18next";
import Styled from "./detail_role.styled";
import type { DetailRoleModel } from "@/src/core/role/domain/models/detail_role_model";
import { useRolesPermissionsProvider } from "@/src/ui/provider/roles_permissions.slice";
import { useCallback } from "react";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_TIME } from "@/src/common/utils";
import { useEffectRunOnce } from "@front_web_mrmilu/hooks";
import { SinglePermissionCheckbox } from "@/src/ui/pages/role/components/single_permission_checkbox/single_permission_checkbox";

interface Props {
  role: Optional<DetailRoleModel>;
}

export default function DetailRole({ role }: Props) {
  const { t } = useTranslation(["role", "common"]);

  const { getRolePermissions, rolePermissions } = useRolesPermissionsProvider((state) => ({
    getRolePermissions: state.getRolePermissions,
    rolePermissions: state.rolePermissions
  }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceRolePermissions = useCallback(debounce(getRolePermissions, DEFAULT_DEBOUNCE_TIME), []);
  useEffectRunOnce(() => {
    debounceRolePermissions(Number(role?.id));
  }, [role, debounceRolePermissions]);

  return (
    <>
      <div>
        <Styled.RoleData>
          <h2>{t("role:data")}</h2>
          <div>
            <FakeInput label={t("role:columns.name")} value={role?.name} />
          </div>
          <div>
            <>
              {rolePermissions !== undefined &&
                Object.entries(rolePermissions).map(([key, value]) => {
                  const options = {
                    create: false,
                    list: false,
                    delete: false,
                    status_change: undefined
                  };
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  options.create = value.create;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  options.list = value.list;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  options.delete = value.delete;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  options.status_change = value?.status_change;
                  return <SinglePermissionCheckbox key={key} name={key} options={options} disabled={true} />;
                })}
            </>
          </div>
        </Styled.RoleData>
      </div>
      <LinkAsButton to={paths.role.index + paths.role.edit + role?.id}>{t("common:actions.edit")}</LinkAsButton>
    </>
  );
}
