import { createStore, useStore } from "zustand";
import RoleController from "@/src/ui/pages/role/controllers/role_controller";

export interface RolePermissionsState {
  defaultPermissions?: undefined;
  rolePermissions?: undefined;
  getDefaultPermissions(): Promise<void>;
  getRolePermissions(role: number): Promise<void>;
}

export const rolesPermissionsProvider = createStore<RolePermissionsState>((set) => ({
  defaultPermissions: undefined,
  rolePermissions: undefined,
  async getDefaultPermissions() {
    const result = await RoleController.defaultRolePermissions();
    set({ defaultPermissions: result });
  },
  async getRolePermissions(role: number) {
    const result = await RoleController.rolePermissions(role);
    set({ rolePermissions: result });
  }
}));

export function useRolesPermissionsProvider(): RolePermissionsState;
export function useRolesPermissionsProvider<T>(selector: (state: RolePermissionsState) => T, equals?: (a: T, b: T) => boolean): T;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useRolesPermissionsProvider(selector?: any, equals?: any) {
  return useStore(rolesPermissionsProvider, selector, equals);
}
