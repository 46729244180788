import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BlackLettersLogo, Home } from "../../assets/icons";
import paths from "@/src/ui/router/paths";
import IconWithTooltip from "../icon_with_tooltip/icon_with_tooltip";
import UserSettings from "../user_settings/user_settings";
import Styled from "./header.styled";
import { useEffect } from "react";
import { useUserProvider } from "../../provider/user.slice";
import Selector from "../selector/selector";
import { useListChannelProvider } from "../../pages/channel/views/channel_page/provider/list_channel.provider";
import store from "@/src/redux/store";
import { setChannel } from "@/src/redux/slices/appSlice";

export default function Header() {
  const { t } = useTranslation("common");
  const user = useUserProvider((state) => state.user);
  const permissions = useUserProvider((state) => state.permissions);
  const userPermissions = useUserProvider((state) => state.userPermission);
  const selectChannel = useUserProvider((state) => state.selectChannel);
  const channel = useUserProvider((state) => state.channel);
  const { rows: channels, getAll: getChannels } = useListChannelProvider((state) => state);

  useEffect(() => {
    async function prepare() {
      if (!userPermissions) {
        await permissions();
      } else {
        if (userPermissions["channels"] && userPermissions["channels"]["list"]) {
          getChannels({ noPagination: true });
        } else {
          store.dispatch(setChannel(user?.channel?.id));
        }
      }
    }

    prepare();
  }, [permissions, userPermissions, getChannels, user?.channel]);

  return (
    <Styled.Header>
      <Styled.HeaderContent>
        <Styled.HomeContent>
          <Link to={paths.home.index}>
            <BlackLettersLogo />
          </Link>
          <Link to={paths.home.index}>
            <IconWithTooltip tooltip={t("header.home")}>
              <Home />
            </IconWithTooltip>
          </Link>
        </Styled.HomeContent>

        <Styled.UserContent>
          {user && !user.channel && (
            <Selector
              label={"Canal"}
              options={channels?.map((channel) => ({
                label: channel ? channel.name : "",
                value: channel ? channel.id : ""
              }))}
              name="channel"
              id="channel"
              value={channel}
              onChange={(value) => {
                if (value) {
                  selectChannel(value as number);
                } else {
                  selectChannel();
                }
              }}
              required
              style={{ width: "200px" }}
            />
          )}
          {/* <Styled.IconsWrapper>
            <IconWithTooltip tooltip={t("header.notifications")}>
              <Notifications />
            </IconWithTooltip>
            <IconWithTooltip tooltip={t("header.settings")}>
              <Settings />
            </IconWithTooltip>
          </Styled.IconsWrapper> */}
          <UserSettings />
        </Styled.UserContent>
      </Styled.HeaderContent>
    </Styled.Header>
  );
}
