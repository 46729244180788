import { spacing } from "@/src/ui/styles/spacing";
import { formSubtitle, px2rem } from "@/src/ui/styles/utils";
import styled from "styled-components";

const Page = styled.div`
  padding-bottom: ${px2rem(spacing.size6)};

  h2 {
    margin-top: ${px2rem(spacing.size4)};
    ${formSubtitle}
  }
`;

export default { Page };
