import { createProvider } from "@/src/common/utils/zustand";
import type { SortDocument } from "@/src/core/document/domain/interfaces/sort_document";
import type { DocumentModel } from "@/src/core/document/domain/models/document_model";
import type { ListState } from "@/src/ui/view_models/list_state";
import type { OrderBy } from "@/src/core/app/domain/models/order";
import DocumentContractController from "@/src/ui/pages/document/controllers/document_contract_controller";
import { getListInitialState } from "@/src/ui/provider/list.slice";
import type { Id } from "@/src/common/utils/types";
import type { FilterDocumentModel } from "@/src/core/document/domain/models/filter_document_model";

interface DocumentProviderBuilderProps {
  filters: FilterDocumentModel;
}

export const useDocumentContractProvider = createProvider<ListState<DocumentModel, FilterDocumentModel, SortDocument>, DocumentProviderBuilderProps>(
  ({ filters }) => {
    return (set, get) => ({
      filters: {
        ...filters,
        contract_id: "",
        name: "",
        description: ""
      },
      setOrderBy(newOrderBy: OrderBy<DocumentModel>) {
        set({ orderBy: newOrderBy });
        get().getAll(get().filters);
      },
      ...getListInitialState(get, set, new DocumentContractController()),
      async handleDeleteSelected(ids: Id[]) {
        set({ isLoading: true });
        try {
          await new DocumentContractController().deleteManyContractDocuments(ids);
        } finally {
          set({ isLoading: false });
        }
        await get().getAll(get().filters);
      }
    });
  }
);
