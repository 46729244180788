import type { Id } from "@/src/common/utils/types";
import useFormController from "@/src/ui/hooks/useFormController";
import { yupCifField, yupNifField, yupRequiredField } from "@/src/ui/utils/yup";
import { yup } from "@front_web_mrmilu/utils";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import type { ObjectShape } from "yup/lib/object";
import { removeNullishValuesFromAnObject } from "@/src/common/utils";
import type { CreateChannelModel } from "@/src/core/channel/domain/models/create_channel_model";

interface FormEditChannelValues {
  id?: Id;
  name: string;
  socialName: string;
  cif: string;
  bankAccountHolder?: string;
  bankAccountNumber?: string;
  fiscalAddress?: string;
}

const formValues: FormEditChannelValues = {
  id: undefined,
  name: "",
  socialName: "",
  cif: "",
  bankAccountHolder: "",
  bankAccountNumber: "",
  fiscalAddress: ""
};

interface Props {
  onSubmitForm: (input: CreateChannelModel) => Promise<void>;
  initialValues?: FormEditChannelValues;
  id?: Id;
}

const required = yupRequiredField();
const cifOrNifRequired = yup
  .string()
  .nullable()
  .test((value) => yupCifField().isValidSync(value) || yupNifField().isValidSync(value));

export default function useEditChannelForm({ onSubmitForm, initialValues = formValues, id }: Props) {
  const [firstSubmit] = useState(false);

  const validationSchema = useMemo(() => {
    const yupObject: ObjectShape = {
      name: required,
      socialName: required,
      cif: cifOrNifRequired
    };

    return yup.object(yupObject);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: firstSubmit,
    validateOnChange: firstSubmit,
    onSubmit: async (values: FormEditChannelValues, { setSubmitting }: FormikHelpers<FormEditChannelValues>) => {
      setSubmitting(false);
      await onSubmitForm(removeNullishValuesFromAnObject(values) as CreateChannelModel);
    }
  });

  const { isFormEmpty } = useFormController({ formik, id, initialValues });

  return { formSchema: formik, isFormEmpty };
}
