import type { Optional } from "@/src/common/utils/types";
import FakeInput from "@/src/ui/components/fake_input/fake_input";
import LinkAsButton from "@/src/ui/components/LinkAsButton/link_as_button";
import paths from "@/src/ui/router/paths";
import { useTranslation } from "react-i18next";
import Styled from "./detail_channel.styled";
import type { DetailChannelModel } from "@/src/core/channel/domain/models/detail_channel_model";
import { number_format } from "@/src/common/utils/money";

interface Props {
  channel: Optional<DetailChannelModel>;
}

export default function DetailChannel({ channel }: Props) {
  const { t } = useTranslation(["channel", "common"]);

  return (
    <>
      <div>
        <Styled.ChannelData>
          <h2>{t("channel:data")}</h2>
          <div>
            <FakeInput label={t("channel:columns.name")} value={channel?.name} />
            <FakeInput label={t("channel:columns.socialName")} value={channel?.socialName} />
            <FakeInput label={t("channel:columns.cif")} value={channel?.cif} />
          </div>
        </Styled.ChannelData>
      </div>
      <div>
        <Styled.ChannelData>
          <h2>{t("channel:form.titles.fiscalData")}</h2>
          <div>
            <FakeInput label={t("channel:columns.bankAccountHolder")} value={channel?.bankAccountHolder} />
            <FakeInput label={t("channel:columns.bankAccountNumber")} value={channel?.bankAccountNumber} />
            <FakeInput label={t("channel:columns.fiscalAddress")} value={channel?.fiscalAddress} />
            <FakeInput label={t("channel:columns.fee")} value={number_format(channel?.fee, false, 2)} />
          </div>
        </Styled.ChannelData>
      </div>
      <LinkAsButton to={paths.channel.index + paths.channel.edit + channel?.id}>{t("common:actions.edit")}</LinkAsButton>
    </>
  );
}
