import { createProvider } from "@/src/common/utils/zustand";
import RoleController from "@/src/ui/pages/role/controllers/role_controller";
import type { AutocompleteState } from "@/src/ui/view_models/autocomplete_state";
import { getAutocompleteInitialState } from "@/src/ui/provider/autocomplete.slice";
import type { Id } from "@/src/common/utils/types";
import type { RoleModel } from "@/src/core/role/domain/models/role_model";

interface AutocompleteProviderBuilderProps {
  filterByActiveRole?: boolean;
}

interface Props extends AutocompleteState {
  filterByActiveRole?: boolean;
  channelId?: Id;
  setChannelId: (channelId?: Id) => void;
}

export const useAutocompleteRoleProvider = createProvider<Props, AutocompleteProviderBuilderProps>(({ filterByActiveRole }) => {
  return (set, get) => ({
    ...getAutocompleteInitialState(get, set),
    filterByActiveRole,
    channelId: undefined,
    setChannelId: (channelId) => set({ channelId }),
    async getByName() {
      set({ isLoading: true });
      try {
        const response = await RoleController.getAllRoles({
          name: get().filterName,
          enabled: get().filterByActiveRole,
          channel: get().channelId
        });
        set({
          items: response.items.map((role: RoleModel) => ({
            label: role.name,
            id: role.id
          }))
        });
      } catch {
      } finally {
        set({ isLoading: false });
      }
    }
  });
});
