import { useNavigate, useRoutes } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import { useListChannelProvider } from "@/src/ui/pages/channel/views/channel_page/provider/list_channel.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { useMutationChannelProvider } from "@/src/ui/pages/channel/provider/mutation_channel.provider";
import paths from "@/src/ui/router/paths";
import DetailChannelPage from "../views/detail_channel_page/detail_channel_page";
import EditChannelPage from "../views/edit_channel_page/edit_channel_page";

const ListChannelPage = lazy(() => import("@/src/ui/pages/channel/views/channel_page/channel_page"));
const CreateChannelPage = lazy(() => import("@/src/ui/pages/channel/views/create_channel_page/create_channel_page"));

const ID_PATH_PARAM = ":channelId";

export default function ChannelPages() {
  const navigate = useNavigate();
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    setRouteList([
      {
        index: true,
        element: (
          <useListChannelProvider.State>
            <ListChannelPage />
          </useListChannelProvider.State>
        )
      },
      {
        path: paths.channel.create,
        element: <CreateChannelPage />
      },
      {
        path: paths.channel.detail + ID_PATH_PARAM,
        element: <DetailChannelPage />
      },
      {
        path: paths.channel.edit + ID_PATH_PARAM,
        element: <EditChannelPage />
      }
    ]);
  }, [navigate]);

  const page = useRoutes(routeList);
  return (
    <AppErrorBoundary key="rate-type-error">
      <useMutationChannelProvider.State>{page}</useMutationChannelProvider.State>
    </AppErrorBoundary>
  );
}
