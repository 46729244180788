import { createProvider } from "@/src/common/utils/zustand";
import type { FilterSettlementModel } from "@/src/core/settlement/domain/models/filter_settlement_model";
import type { SortSettlement } from "@/src/core/settlement/domain/interfaces/sort_settlement";
import type { OrderBy } from "@/src/core/app/domain/models/order";
import type { ListState } from "@/src/ui/view_models/list_state";
import type { SettlementModel } from "@/src/core/settlement/domain/models/settlement_model";
import { getListInitialState } from "@/src/ui/provider/list.slice";
import SettlementController from "@/src/ui/pages/settlement/controllers/settlement_controller";

export const useListSettlementProvider = createProvider<ListState<SettlementModel, FilterSettlementModel, SortSettlement>>(() => (set, get) => ({
  filters: {
    id: undefined
  },
  setOrderBy(newOrderBy: OrderBy<SortSettlement>) {
    set({ orderBy: newOrderBy });
    get().getAll(get().filters);
  },
  ...getListInitialState(get, set, new SettlementController())
}));
