import { ThemeProvider } from "@mui/material/styles";
import { GlobalStyles } from "@/src/ui/styles/globals";
//import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import { useRoutes } from "react-router-dom";
import { routes } from "@/src/ui/router/routes";
import theme from "@/src/ui/styles/theme";
import { Modal } from "@/src/ui/components/modal/modal";
import { MainLoader } from "@/src/ui/components/main_loader/main_loader";
import { SuspenseMainLoader } from "@/src/ui/components/suspense_main_loader/suspense_main_loader";
import { Provider } from "react-redux";
import store from "../redux/store";
//import { useEffect } from "react";

function App() {
  const page = useRoutes(routes);
  //const location = useLocation();
  //const navigate = useNavigate();

  /*useEffect(()=>{
    if(location.pathname !== "/"){
      navigate(location.pathname, { replace: true })
    }
    
  },[window.location.href])*/

  return (
    <>
      <SuspenseMainLoader>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Modal />
            <MainLoader />
            {page}
          </ThemeProvider>
        </Provider>
      </SuspenseMainLoader>
    </>
  );
}

export default App;
