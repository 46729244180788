/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormikProvider } from "formik";
import { useTranslation } from "react-i18next";
import Styled from "./edit_role.styled";
import { InputFormik } from "@/src/ui/components/input/input";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useShowToast from "@/src/ui/hooks/useShowToast";
import { useEffectRunOnce } from "@front_web_mrmilu/hooks";
import paths from "@/src/ui/router/paths";
import { type ChangeEvent, useCallback, useMemo } from "react";
import { useMutationRoleProvider } from "../../provider/mutation_role.provider";
import type { CreateRoleModel } from "@/src/core/role/domain/models/create_role_model";
import useEditRoleForm from "../../controllers/edit_role_controller";
import { SinglePermissionCheckbox } from "@/src/ui/pages/role/components/single_permission_checkbox/single_permission_checkbox";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_TIME } from "@/src/common/utils";
import { useRolesPermissionsProvider } from "@/src/ui/provider/roles_permissions.slice";

const useEditContactInitialValues = () => {
  const role = useMutationRoleProvider((state) => state.item);
  return useMemo(() => {
    if (!role) return undefined;

    return {
      ...role
    };
  }, [role]);
};

export default function EditRole() {
  const { roleId } = useParams();
  const ediRole = useMutationRoleProvider((state) => state.edit);
  const getRoleById = useMutationRoleProvider((state) => state.getById);
  const role = useMutationRoleProvider((state) => state.item);
  const { showToast } = useShowToast();
  const navigate = useNavigate();
  const { t } = useTranslation(["role", "common"]);
  const initialValues = useEditContactInitialValues();
  const { getRolePermissions, rolePermissions } = useRolesPermissionsProvider((state) => ({
    getRolePermissions: state.getRolePermissions,
    rolePermissions: state.rolePermissions
  }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceRolePermissions = useCallback(debounce(getRolePermissions, DEFAULT_DEBOUNCE_TIME), []);

  const onSubmitForm = async (input: CreateRoleModel) => {
    if (!role) throw new Error("Role it's null");
    await ediRole(input, Number(roleId));
    navigate(-1);
    showToast({ message: t("role:actions.edited") });
  };

  const { formSchema, isFormEmpty } = useEditRoleForm({
    onSubmitForm,
    initialValues,
    id: Number(roleId)
  });

  const getRole = useCallback(async () => {
    if (roleId) {
      const id = Number(roleId);
      const response = await getRoleById(id);
      if (!response) {
        navigate(paths.role.index);
      }
    }
  }, [getRoleById, navigate, roleId]);

  function handleChange(e: ChangeEvent, checked: boolean) {
    const section = e?.target.getAttribute("id");
    const attr = e?.target.getAttribute("name");
    if (section && attr) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rolePermissions[section][attr] = checked;
    }
  }

  useEffectRunOnce(() => {
    formSchema.setFieldValue("values", rolePermissions);
  }, [rolePermissions]);

  useEffectRunOnce(() => {
    getRole();
    debounceRolePermissions(Number(roleId));
  }, [getRole, debounceRolePermissions, roleId]);

  return (
    <>
      <Styled.Header>
        <h1>{t("role:actions.edit")}</h1>
        <p>{t("common:editDescription")}</p>
      </Styled.Header>
      <FormikProvider value={formSchema}>
        <Form noValidate autoComplete="off" placeholder={""}>
          <Styled.RoleData>
            <Styled.FormGrid>
              <InputFormik name="name" label={t("role:columns.name")} id="name" />
            </Styled.FormGrid>

            <Styled.FormGrid>
              <>
                {rolePermissions !== undefined &&
                  Object.entries(rolePermissions).map(([key, value]) => {
                    const options = {
                      create: false,
                      list: false,
                      delete: false,
                      status_change: undefined
                    };
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    options.create = value.create;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    options.list = value.list;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    options.delete = value.delete;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    options.status_change = value?.status_change;
                    return <SinglePermissionCheckbox onChange={handleChange} key={key} name={key} options={options} />;
                  })}
              </>
            </Styled.FormGrid>
          </Styled.RoleData>

          <Styled.Button>
            <Button variant="contained" disabled={isFormEmpty} type="submit">
              {t("common:actions.save")}
            </Button>
          </Styled.Button>
        </Form>
      </FormikProvider>
    </>
  );
}
