import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { ListController } from "@/src/common/interfaces/list_controller";
import type { SortDocument } from "@/src/core/document/domain/interfaces/sort_document";
import type { DocumentModel } from "@/src/core/document/domain/models/document_model";
import type { FilterDocumentModel } from "@/src/core/document/domain/models/filter_document_model";
import type { MutationController } from "@/src/common/interfaces/mutation_controller";
import type { Filters } from "@/src/core/app/domain/models/filters";
import { withErrorHandler } from "@/src/common/utils/errors";
import type { GetDocumentsContractUseCase } from "@/src/core/contract/domain/use_cases/get_documents_contract_use_case";
import { ExportArgumentsModel } from "@/src/core/app/domain/models/export_argument";
import type { GetDocumentDetailContractUseCase } from "@/src/core/document/domain/use_cases/get_document_contract_detail_use_case";
import type { ExportDocumentsUseCase } from "@/src/core/document/domain/use_cases/export_documents_use_case";
import type { Id } from "@/src/common/utils/types";
import type { CreateDocumentContractModel } from "@/src/core/document/domain/models/create_document_contract_model";
import type { CreateDocumentContractUseCase } from "@/src/core/document/domain/use_cases/create_document_contract_use_case";
import type { EditDocumentContractUseCase } from "@/src/core/document/domain/use_cases/edit_document_contract_use_case";
import type { DeleteManyDocumentContractUseCase } from "@/src/core/document/domain/use_cases/delete_many_documents_contract_use_case";
import type { DeleteManyDocumentUseCase } from "@/src/core/document/domain/use_cases/delete_many_documents_use_case";

export default class DocumentContractController
  implements ListController<DocumentModel, FilterDocumentModel, SortDocument>, MutationController<DocumentModel>
{
  async getAll(filter: Filters<FilterDocumentModel, SortDocument>) {
    const documentsUseCase = await locator.get<IocProvider<GetDocumentsContractUseCase>>(TYPES.GetDocumentsContractUseCase)();
    return await withErrorHandler(documentsUseCase.execute(filter));
  }

  static async create(input: CreateDocumentContractModel) {
    const createContactUseCase = await locator.get<IocProvider<CreateDocumentContractUseCase>>(TYPES.CreateDocumentContractUseCase)();
    return await withErrorHandler(createContactUseCase.execute(input));
  }

  async getOneById(id: Id) {
    const getDocumentUseCase = await locator.get<IocProvider<GetDocumentDetailContractUseCase>>(TYPES.GetDocumentDetailContractUseCase)();
    return await withErrorHandler(getDocumentUseCase.execute(id));
  }

  static async edit(input: CreateDocumentContractModel, id: Id) {
    const editDocumentUseCase = await locator.get<IocProvider<EditDocumentContractUseCase>>(TYPES.EditDocumentContractUseCase)();
    return withErrorHandler(editDocumentUseCase.execute(input, id));
  }

  async deleteMany(ids: Id[]) {
    const deleteManyUseCase = await locator.get<IocProvider<DeleteManyDocumentUseCase>>(TYPES.DeleteManyDocumentUseCase)();
    await withErrorHandler(deleteManyUseCase.execute(ids));
  }

  async deleteManyContractDocuments(ids: Id[]) {
    const deleteManyUseCase = await locator.get<IocProvider<DeleteManyDocumentContractUseCase>>(TYPES.DeleteManyDocumentContractUseCase)();
    await withErrorHandler(deleteManyUseCase.contractDocuments(ids));
  }

  async export({ filters, ids }: ExportArgumentsModel<FilterDocumentModel, SortDocument>) {
    const exportUseCase = await locator.get<IocProvider<ExportDocumentsUseCase>>(TYPES.ExportDocumentsUseCase)();
    const exportArguments = new ExportArgumentsModel({ filters, ids });
    return await withErrorHandler(exportUseCase.execute(exportArguments));
  }
}
