import type { Id } from "@/src/common/utils/types";
import { createProvider } from "@/src/common/utils/zustand";
import type { CreateDocumentContractModel } from "@/src/core/document/domain/models/create_document_contract_model";
import DocumentContractController from "@/src/ui/pages/document/controllers/document_contract_controller";
import { getMutationInitialState } from "@/src/ui/provider/mutation.slice";
import type { MutationState } from "@/src/ui/view_models/mutation_state";
import type { DetailDocumentContractModel } from "@/src/core/document/domain/models/detail_document_contract_model";

export const useMutationDocumentContractProvider = createProvider<MutationState<DetailDocumentContractModel, CreateDocumentContractModel>>(
  () => (set) => ({
    async edit(input: CreateDocumentContractModel, id: Id) {
      const response = await DocumentContractController.edit(input, id);
      set({ item: response });
      return response;
    },
    ...getMutationInitialState(set, new DocumentContractController())
  })
);
